.cardContainer {
  margin-top: 2rem;
  overflow-x: scroll;
}

.cardContainer::-webkit-scrollbar {
  height: 0.5rem;
}

.cardContainer::-webkit-scrollbar-thumb {
  background-color: var(--ambient-basePlus75);
  opacity: 0.5;
  border-radius: 10px;
}

.cardContent {
  min-width: 1280px;
}

.cardTitle {
  display: flex;
  justify-content: space-between;
}

.headerLeftAlign {
  display: flex;
  flex-direction: column;
}

.headerRightAlign {
  font-size: 0.75rem;
}

.headerTitle {
  margin-top: -0.25rem;
  font-weight: 600;
  font-size: 1rem;
}

.energyProTitleContainer {
  margin: 0.25rem 0;
}

.energyProTitle {
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--accent-base);
  margin-right: 1rem;
}

.equipmentTitle {
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--accent-base);
  margin-right: 1rem;
  cursor: pointer;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breakerName {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.liveReadingTable {
  font-size: 0.75rem;
  display: grid;
  grid-template-columns:
    [equipment] 5fr
    [index] 5fr
    [breakers] 6fr
    [ct-type] 5fr
    [phase] 2fr
    [ct-polarity] 4fr
    [active-power] 3fr
    [current] 3fr
    [voltage] 3fr
    [power-factor] 3fr
    [sc-status] 5fr;
}

.liveReadingTableWithoutSC {
  font-size: 0.75rem;
  display: grid;
  grid-template-columns:
    [equipment] 5fr
    [index] 5fr
    [breakers] 6fr
    [ct-type] 5fr
    [phase] 2fr
    [ct-polarity] 4fr
    [active-power] 3fr
    [current] 3fr
    [voltage] 3fr
    [power-factor] 3fr;
}

.liveReadingTableByIndex {
  font-size: 0.75rem;
  display: grid;
  grid-template-columns:
    [index] 5fr
    [breakers] 6fr
    [equipment] 5fr
    [ct-type] 5fr
    [phase] 2fr
    [ct-polarity] 4fr
    [active-power] 3fr
    [current] 3fr
    [voltage] 3fr
    [power-factor] 3fr
    [sc-status] 5fr;
}

.liveReadingTableByIndexWithoutSC {
  font-size: 0.75rem;
  display: grid;
  grid-template-columns:
    [index] 5fr
    [breakers] 6fr
    [equipment] 5fr
    [ct-type] 5fr
    [phase] 2fr
    [ct-polarity] 4fr
    [active-power] 3fr
    [current] 3fr
    [voltage] 3fr
    [power-factor] 3fr;
}

.headerStyle {
  font-size: 0.625rem;
  padding: 0.5rem 0;
  padding-left: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--ambient-basePlus90);
  border-bottom: 1px solid var(--ambient-basePlus90);
  margin-bottom: 0.25rem;
}

.cellStyle {
  font-size: 0.75rem;
  padding-left: 1rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
}

.highlightedCellStyle {
  font-size: 0.75rem;
  padding-left: 1rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  background-color: var(--shade-05);
}

.readingNumber {
  width: 98%;
  text-align: right;
}

.separator {
  height: 0.25rem;
  border-bottom: 1px solid var(--ambient-basePlus90);
  margin-bottom: 0.25rem;
}

.updateBreakerModalContent {
  padding: 2rem;
  font-size: 1.25rem;
  text-align: center;
}

.bold {
  font-weight: bold;
}

.selectContainer {
  margin-top: -0.4rem;
}

.deltaPanelEnergyProSecondaryRowDisplay {
  margin-top: 0.5rem;
}

@media (max-width: 1349px) {
  .deltaPanelEnergyProSecondaryRowDisplay {
    margin-top: 1.5rem;
  }
}

.circuitStatus {
  padding: 0 0.5rem 0 0.5rem;
}

.circuitStatus:first-child {
  padding-left: 0;
}

.circuitStatus:not(:last-child) {
  border-right: solid 1.5px var(--ambient-basePlus75);
}

.scrollContainer {
  overflow-y: scroll;
  max-height: calc(100vh - 32rem);
  direction: rtl;
  margin-left: -0.5rem;
  border-bottom: 1px solid var(--ambient-basePlus90);
}

.scrollContainer > * {
  direction: ltr;
}

.scrollContainer::-webkit-scrollbar {
  width: 0.5rem;
  position: absolute;
  top: 0;
}

.scrollContainer::-webkit-scrollbar-thumb {
  background-color: var(--ambient-basePlus75);
  opacity: 0.5;
  border-radius: 10px;
}

.headerContainer {
  padding-left: 0.5rem;
  margin-left: -0.5rem;
}
